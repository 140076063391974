.syntura-dark-bg {
  background-color: inherit;

  &::before {
    content: '';
    background-image: url("../../public/img/syntura-login-bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    transform: scaleX(-1);
  }
}

.syntura-sidebar-dark-bg {
  background-color: inherit;

  &::before {
    content: '';
    background-image: url("../../public/img/syntura-login-bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    transform: scaleX(-1);
  }
}

.syntura-sidebar-bg {
  background-position: bottom;
}

.white-card-bg {
  background-color: inherit;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/img/white-bg-gradient.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleY(-1);
    z-index: -1;
  }
}

.form-control {
  border: 1px solid #b8b4b4;
}

.sidebar {
  color: #fff;
}

.bg-syntura-primary {
  background-color: #9066f3 !important;
}

.bg-syntura-dark {
  background-color: #000000 !important;
}

.callout-syntura-info {
  border-left-color: #66e4ff;
}

.callout-syntura-info h4 {
  color: #66e4ff;
}

.callout-syntura-primary {
  border-left-color: #9066f3;
}

.callout-syntura-primary h4 {
  color: #9066f3;
}

.callout-syntura-tertiary {
  border-left-color: #ffa966;
}

.callout-syntura-tertiary h4 {
  color: #ffa966;
}

.btn-danger {
  color: white;
}

.btn-danger:hover {
  color: white;
  background-color: #EE6971;
  border-color: #EE6971;
}

.btn-primary {
  color: #fff;
  background-color: #9066F3;
  border-color: #9066F3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #9066F3;
  border-color: #9066F3;
}

.btn-warning {
  color: #000;
  background-color: #FFA966;
  border-color: #FFA966;
}

.btn-warning:hover {
  color: #000;
  background-color: #FFA966;
  border-color: #FFA966;
}

.text-primary {
  color: #9066F3 !important;
}

.border-primary {
  border-color: #9066F3 !important;
}

.breadcrumb-item a {
  color: #FFA966;
}

a {
  color: #FFA966;
}

.dropdown-toggle.btn {
  align-items: center;
  background: white;
  color: black;
  border: 0.0625rem solid rgb(var(--primary-color));
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-self: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.625rem 1.125rem;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.dropdown-toggle.btn:hover {
  background: black;
  color: white;
}