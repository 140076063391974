.phone-input-border{
    border : 1px solid #dddbdb!important;

}
.phone-input-border-error{
  border :1px solid #EE6971!important; 
  padding-right: calc(1.5em + 0.75rem)!important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e57373' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e57373' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")!important;;
  background-repeat: no-repeat!important;;
  background-position: center right calc(0.375em + 0.1875rem)!important;;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)!important;;
 }


