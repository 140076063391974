// Custom styles for this template
@import "animations";
@import "typography";
@import "forms";
@import "tables";
@import "utils";
@import "components/react-big-calendar";
@import "components/monitoring-widget";
@import "components/login-dropdown";
@import "components/rich-editor";
@import "components/card";
@import "components/accordion";
@import "components/simple-loader";
@import "components/no-data-widget";
@import "components/phone-input";
@import "components/scroll";
@import "components/star";

// ATHauss font face declarations
@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 300;
  src: url('../../../public/fonts/ATHauss/Light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: italic;
  font-weight: 300;
  src: url('../../../public/fonts/ATHauss/LightItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/ATHauss/Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 600;
  src: url('../../../public/fonts/ATHauss/Semibold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: italic;
  font-weight: 600;
  src: url('../../../public/fonts/ATHauss/SemiboldItalic.woff2') format('woff2');
}

body {
  font-family: at-hauss,Helvetica Neue,sans-serif;
}
.aside-menu {
  .nav-tabs .nav-link,
  .callout {
    border-radius: 0;
  }
}
nav.sidebar-nav {
  .form-control {
    border: 0;
    background: theme-color('dark');
    color: #fff;
    &::-webkit-input-placeholder { color: rgba(255,255,255,.7); }
    &:-moz-placeholder { color: rgba(255,255,255,.7); }
    &::-moz-placeholder { color: rgba(255,255,255,.7); }
    &:-ms-input-placeholder { color: rgba(255,255,255,.7); }
  }
}
.aside-menu {
  .nav-tabs .nav-link,
  .callout {
    border-radius: 0;
  }
}
.badgebox
{
  opacity: 0;
}
.badgebox + .badge
{
  /* Move the check mark away when unchecked */
  text-indent: -999999px;
  /* Makes the badge's width stay the same checked and unchecked */
  width: 27px;
  margin-left: 4px;
  color: #000;
}
.badgebox:checked + .badge
{
  /* Move the check mark back when checked */
  text-indent: 0;
}
.app-footer a {
  color: theme-color('light');
}
.badge-pink {
  color: $white;
  background-color: $pink;
}
.modal-xlg{
  max-width: 1200px;
}
.dashboard-primary{
  background-color: lighten(theme-color("primary"), 17%)
}
.card-header .card-actions div button {
    border: 0;
    padding-right:10px;
    color: $gray-500;
}
.item-graph {
    margin-left: -10px;
    font-size: 16px;
    color: $gray-500;
    display: inline;
}
.item-graph .btn-link {
    color: $gray-500;
}
.datepickerheader h4.modal-title {
    width: 100%;
}
.datepickerheader h4.modal-title .datepicker {
    float:right;
    margin-right: 30px;
}
.cursor-pointer {
  cursor: pointer
}
.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.card-body-blue{
  background-color: rgba(107, 164, 184, 0.2)
}
.card-body-dark{
  background-color: rgba(51, 63, 72, 0.05)
}
.card-body-orange{
  background-color: rgba(255, 158, 27, 0.05)
}
.b-b-2-orange{
  border-bottom: 2px solid #FFC599 !important;
}
.b-b-2-dark{
  border-bottom: 2px solid theme-color('secondary') !important;
}
span.cross {
  color: #9da0a8;
  &::before,
  &::after {
    position: absolute;
    left: 43px;
    top: 23px;
    content: '';
    height: 50px;
    width: 7px;
    background-color: #9da0a8;
  }
  &::before {
    transform: rotate(90deg);
  }
}
.grey-icon{
  float: left;
  border-radius: 1px;
  width: 30px;
  height: 30px;
  display: block;
  background: #999;
  color: white;
  margin-left: 3px;
  font-size: 130%;
  &__inner{
    padding: 0 5px 0 7px;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.caret{
  margin-right: 10px;
}
.caret:after{
  color:  $gray-600;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 0;
  top: 50%;
}
.light-border{
  &--bottom{
    border-bottom:  1px solid #eee;
  }
}
.light-border--bottom {
  border-bottom:  1px solid #eee;
}
.light-border--top {
  border-top:  1px solid #eee;
}
.MsoNormal {
  margin-bottom: 0;
}
