.card, .social-box {
  border-top-width: 0;
  border-bottom-width: 2 * $card-border-width;
}

[class*="card-outline-"] {
  border-top-width: 1px !important;
}

[class*="card-accent-"] {
  border-top-width: 2px !important;
}


