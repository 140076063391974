.show-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.show-scroll::-webkit-scrollbar:vertical {
    width: 12px;
}

.show-scroll::-webkit-scrollbar:horizontal {
    height: 12px;
}

.show-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.show-scroll::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
}