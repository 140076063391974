._loading_overlay_wrapper{
    width: 100%;
}
._loading_overlay_overlay{
    width: 100%;
    background: rgba(255,255,255,0.8)!important;
  

}
._loading_overlay_content{
    width: 100%;
    color: $hSoDark!important;

}
._loading_overlay_spinner{
   svg circle {
       stroke: $hSoDark!important;
      
   }
}