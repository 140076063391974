@import "../../scss/utils.scss";

.syntura-btn:before {
  border: pxToRem(1px) solid rgb(var(--secondary-color));
  border-radius: .5rem;
  content: "";
  -webkit-mask-image: linear-gradient(var(--buttonangle), transparent 0, #000 100%);
  mask-image: linear-gradient(var(--buttonangle), transparent 0, #000 100%);
  position: absolute;
  top: pxToRem(-1px);
  bottom: pxToRem(-1px);
  left: pxToRem(-1px);
  right: pxToRem(-1px);
  transition: --buttonangle .3s cubic-bezier(.77, 0, .175, 1);
  z-index: 1
}

.syntura-btn {
  align-items: center;
  color: #fff;
  border: pxToRem(1px) solid rgb(var(--primary-color));
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
  justify-self: center;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1;
  padding: .625rem 1.125rem;
  position: relative;
  text-decoration: none;
  transition: all .2s ease-in-out;
  white-space: nowrap;

  &.small {
    font-size: 0.75rem;
    padding: 0.6rem 1.25rem;
  }

  &.medium {
    font-size: 0.875rem;
    padding: 0.625rem 1.125rem;
  }

  &.large {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  @media (min-width: 768px) {
    &.small {
      font-size: 0.875rem;
      padding: 0.6rem 1.25rem;
    }

    &.medium {
      font-size: 1.125rem;
      padding: 1rem 1.75rem;
    }

    &.large {
      font-size: 1.25rem;
      padding: 1.25rem 2rem;
    }
  }
}

.syntura-btn:hover {
  background: #000;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}

.syntura-btn:hover:before {
  --buttonangle: 0deg
}

.syntura-btn.primary {
  background: #fff;
  color: #000;
}

.syntura-btn.primary:hover {
  background: #000;
  color: #fff;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}

.syntura-btn.tertiary {
  background: linear-gradient(90deg,
      #00D2FF 0%,
      #2E8DF8 40%,
      #594CF1 81%,
      #6B33EF 100%);
}

.syntura-btn.tertiary:hover {
  background: #000;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}
