.star {
    width: 30px;
    transition: 0.2s;
    stroke: #000;
    fill: #fff;
    padding: 1px;
    stroke-width: 10px;
    &:hover {
        padding: 0px;
        //background: $hSoBlue;
        background: #eee;
        border-radius: 50%;
    }

    &--services:hover{
      background: #ccc;

    }
    &--favourited {
        fill: #FFC599;
        &:hover {
            fill: #fff;
        }
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.lds-ring {
  position: relative;
  width: 30px;
 
  }
  .lds-ring div {
    box-sizing: border-box;
    position: absolute;
    margin-top:5px;
    width: 20px;
    height: 20px;
    border: 1px solid #666;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #666 transparent transparent transparent;
  }
  .lds-services{
    position: absolute;
   
    top: 0px;
  }
  .lds-services-dcconnect{
    position: absolute;
  
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  