
.logindropdown {
  transform: translate3d(-7px, 61px, 0px)!important;
  background-color: #f7f7f8;
  border:0;
  width: calc(100% + 20px );
}


.logindropdown .dropdown-header {
  background-color: $blue;

  border:0;
}

.logindropdown  {
  color: #9da0a8;
  a:hover{
    text-decoration: none;
  }
  i{
    font-size: 22px;
  }
  button.dropdown-item{
    button{
      text-decoration: none;

    }
    &:hover{
      color: $hSoDark!important;

      text-decoration: none;
      span i{
        color: $blue!important;



      }
    }
  }
}
header.navbar > .img-avatar {
  height: 36px;
  margin: -3px 10px 0 10px;
}

.logindropdown .acinfo {
  text-align: center;
  margin-top: 16px;
}
