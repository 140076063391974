@property --cardalpha {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@property --buttonangle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 90deg;
}

.main-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
}

.login-footer {
  position: absolute;
  bottom: 0;
  right: 0;

  a {
    color: #FFC599;
  }
}

.error-card {
  top: 1rem;
}
