.complex-table.monitoring-table.ReactTable{

  .rt-th:nth-child(1), .rt-td:nth-child(1){
    max-width: 60px;
  }
}


.complex-table, .ReactTable{
 
  border-spacing: 0;
  .rt-tr .rt-td:last-child, .rt-th:last-child,  .rt-td:nth-last-child(2), .rt-th:nth-last-child(2){
    border-right: 0 !important;
   
    
  }

  
 
  th , .rt-th{
    border-top: none;
    border-bottom: none;
    font-size: 18px;
    border-right: 1px solid rgb(239, 240,240);
    color: theme-color("light");
  }
 
  td , .rt-td{
    margin: 0;
    vertical-align: middle;
    color: theme-color("light");
    font-weight: bold;
    border-top: 1px solid rgb(239, 240,240);
    border-right: 1px solid rgb(239, 240,240);
  }

}

.ReactTable .rt-resizable-header   , .ReactTable .rt-resizable-header:focus  {
overflow: hidden;

box-shadow: none;
}

.monitoring-table{
tr td:first-child, th:first-child{
  width: 60px !important;
}
tr td:last-child, th:last-child{
  border-right: 0 !important;
  width: 60px !important;
}
}


.ReactTable .rt-thead .rt-th.-sort-asc,.ReactTable .rt-thead .rt-td.-sort-asc {
box-shadow: inset 0 3px 0 0 #66E4FF;
}
.ReactTable .-pagination {
color: #9da0a8 !important;
.-center{
  color: #9da0a8 !important;

}
.-pageJump input{
  color: #9da0a8 !important;

}
select{
  color: #9da0a8 !important;
}

}

.ReactTable .rt-thead .rt-th.-sort-desc,.ReactTable .rt-thead .rt-td.-sort-desc {
box-shadow: inset 0 -3px 0 0 #66E4FF;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
background-color: rgba(255, 158, 27, 0.2) !important;
}

.ReactTable .-pagination .-btn {
display: inline-block;
font-weight: normal;
line-height: 1.25;
text-align: center;
white-space: nowrap;
vertical-align: middle;
user-select: none;
border: 1px solid #FFC599;
padding: 0.5rem 1rem;
font-size: 0.875rem;
border-radius: 0.25rem;
transition: all 0.2s ease-in-out;

width: 100%;
height: 100%;
cursor: pointer;
outline: none;
color: #FFC599;
background-image: none;
background-color: transparent;

}

.ReactTable .-pagination .-btn:not([disabled]):hover {
color: #fff;
background-color: #FFC599;
border-color: #FFC599;
}

.ReactTable .rt-noData {
display:none;

}
