
.monitoring-widget{
  display: flex;
  align-items: stretch;

  &__middle{
    height: 350px;

  }
  &__top-half{
    height: 200px;
  }
  &__bottom-half{
    height: 150px;
  }
  &__spark-lines-row{
    height: 180px;
  }
  &__uptime{
    font-size: 30px;
    line-height: 25px;
    &__number{
      color: $hSoBlue!important;

    };
    &__ms{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      margin-left: 10px;
    };
  };

  &__latency{
    line-height: 45px!important;
    font-size: 30px;

    &__ms{
      // background: black;
      font-size: 25px;
      font-weight: bold;
      line-height: 40px!important;

    };

  };


  @media (min-width: 1200px) and (max-width: 1650px) {
    &__latency{
      line-height: 45px!important;
      font-size: 20px;
  
      &__ms{
        // background: black;
        font-size: 15px;
        font-weight: bold;
        line-height: 40px!important;
  
      };
  
  };
}

  &--port {
    color: white;

    &__bottom-border{
      border-bottom: 1px solid #fff;
    }

    h1{
      font-size: 30px;
      font-weight: bold;
    }
  }


}

svg .percent-text{
  display: none!important;
}