// core overrides

$border-color:                        $gray-200;


// Navbar

$navbar-height:                       75px;
$navbar-bg:                           rgb(247,247,248);
$navbar-border:                       0;
$navbar-brand-width:                  220px;
$navbar-brand-bg:                     theme-color("dark");
$navbar-brand-logo-size:              10rem auto;
$navbar-brand-border:                 0;

$navbar-color:                        $white;
$navbar-hover-color:                  darken($navbar-color, 10%);
$navbar-active-color:                 darken($navbar-color, 20%);
$navbar-disabled-color:               lighten($navbar-color, 20%);


// Sidebar
$sidebar-bg:                          theme-color('dark');
$sidebar-width:                       220px;
$mobile-sidebar-width:                220px;

// Sidebar Navigation

$sidebar-nav-title-padding-y:         .95rem;
$sidebar-nav-title-color:             theme-color("light");

$sidebar-nav-link-hover-bg:           darken($sidebar-bg, 10%);

$sidebar-nav-link-active-bg:          darken($sidebar-bg, 10%);
$sidebar-nav-link-active-icon-color:  $blue;

$sidebar-nav-dropdown-bg:             darken($sidebar-bg, 5%);

// Breadcrumb

$breadcrumb-borders:                  0;

// Footer

$footer-bg:                           rgb(239,240,240);
$footer-color:                        #fff;


:root {
--light-grey: 246, 246, 246;
--medium-grey: 211, 221, 225;
--white: 255, 255, 255;
--black: 0, 0, 0;
--Primary-Bespoke-Blue: 0, 210, 255;
--Primary-People-Purple: 70, 0, 235;
--Gradient-Purple: 107, 51, 239;
--Tones-Black: 0, 0, 0;
--Tones-Black-90: 25, 25, 25;
--Tones-Black-50: 128, 128, 128;
--Tones-Grey: 230, 230, 230;
--Tones-Platinum: 242, 242, 242;
--Tones-White: 255, 255, 255;
--Secondary-Orange: 255, 169, 102;
--Secondary-Red: 238, 105, 113;
--Secondary-Blue: 102, 228, 255;
--Secondary-Purple: 144, 102, 243;
--primary-color: 0, 210, 255;
--secondary-color: 70, 0, 235;
}
@property --cardalpha {
  syntax: "<number>";
  inherits: false;
  initial-value: 0
}

@property --buttonangle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 90deg
}