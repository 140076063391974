

.truncate {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-black{
  color: $black!important ;
  a{
    color: $black ;
  }
}
.text-light {
  color:  $gray-600;
}


.percent-text{


  @media (min-width: 768px)  {
    font-size: 20px!important;
    transform: translate(0px, -10px);


  };
}