.accord{

  &__bar{
    background: $body-bg;
    border-radius: 10px;
  }
  &--resolved{
    border-left: 10px solid $success;
  }
  &--email-outbound{
    border-left: 10px solid $hSoBlue;

  }
  &--email-inbound{
    border-left: 10px solid $hSoOrange;

  }
  &--phone{
    border-left: 10px solid $hSoDark;

  }
}