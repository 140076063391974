.cls-1 {
  fill: #858c91;
  width: 20px;
  margin: 5px 10px 5px 0;

}
.white-svg{
  fill: white;
}

.blue-svg{
  fill: $blue;
}

.refresh-svg {
 width: 25px;
 fill: white;
}
.nav-link.active .cls-1{
  fill: $blue;
}

.sidebar  a.nav-link i{
  color: #fff;
  opacity: 0.5;
}
.sidebar  a.nav-link.active i{
  color: $blue;
  opacity: 1;
}

.cls-2 {

  fill: #ffffff;
  width: 30px;
  margin: 0 5px 0 0;

}

.analytics{
  svg{
    path{
      fill: #ccc;
    }

    background: #ccc;
  }

}

.large-green-tick{
  fill:$green;
  width:150px;
  margin: auto auto;
  margin-top: 0px;
}
.error-icon{
  fill: $red;
 &--top{
   width: 100px;
 }
}

.monitoring-widget-cloud-up{
  width: 30px;
  margin: 0 5px 0 0;
  fill: $hSoBlue;
}

.monitoring-widget-cloud-down{
  width: 30px;
  margin: 0 5px 0 0;
  fill: $hSoOrange;
}
